import React from 'react'
import {observer} from 'mobx-react'
import {Doughnut, HorizontalBar, Bar} from 'react-chartjs-2'
import getStore from '../Store'
import File from '../components/File'
import MarkdownIt from 'markdown-it'
const md = new MarkdownIt()

const calculateChanges = data => {
  const {current, wanted} = data
  return {
    akcje: wanted.akcje - current.akcje,
    dluzne: wanted.dluzne - current.dluzne,
    gotowka: wanted.gotowka - current.gotowka,
    alternatywne: wanted.alternatywne - current.alternatywne,

    pln: wanted.pln - current.pln,
    usd: wanted.usd - current.usd,
    eur: wanted.eur - current.eur,
    gbp: wanted.gbp - current.gbp
  }
}


const sumValue = (agg, el) => agg + el.value
const subclassesSort = data => {
  const walletSum = data.currentConcrete.reduce(sumValue, 0)
  return subclasses.map((el, ind) => {
    const entries = data.currentConcrete.filter(el => el.subclass === ind)
    const entriesSum = entries.reduce(sumValue, 0)
    return {
      name: el,
      weight: entriesSum / walletSum,
      modelWeight: data.wanted.subclasses[ind],
      entries
    }
  })
}

const subclassesAggregate = data => subclasses.map((el, ind) => ({
  name: el,
  entries: data.recommendations.filter(el => el.subclass == ind)
}))

const subclasses = [
  'Polski rynek pieniężny',
  'Polskie obligacje skarbowe',
  'Polskie obligacje korporacyjne',
  'Obligacje z rynków rozwiniętych',
  'Obligacje z rynków rozwijających się',
  'Obligacje korporacyjne o ratingu inwestycyjnych',
  'Polskie akcje dużych spółek',
  'Gotówka'
]

const StateCharts = props => {
  const input = props.input
  const structureData = {
    datasets: [{
        data: [
          input.akcje, input.dluzne, input.gotowka, input.alternatywne
        ],
        backgroundColor: [
          '#192a56', '#c23616', '#0097e6', '#44bd32'
        ]
    }],
    labels: [
        'Akcje',
        'Instrumenty dłużne',
        'Gotówka',
        'Alternatywne'
    ]
  }
  const currencyData = {
    datasets: [{
        data: [
          input.pln, input.eur, input.gbp, input.usd
        ],
        backgroundColor: [
          '#00b894', '#00cec9', '#6c5ce7', '#fdcb6e'
        ]
    }],
    labels: [
        'PLN',
        'EUR',
        'GBP',
        'USD'
    ]
  }
  return <div className='columns'>
    <div className='column'>
      <Doughnut data={structureData} />
    </div>
    <div className='column'>
      <Doughnut data={currencyData} />
    </div>
  </div>
}

const CurrentConcrete = props => {
  return <table className="table is-fullwidth">
    <thead>
      <tr>
        <th>Podklasa</th>
        <th>Nazwa</th>
        <th>Wartość</th>
        <th>Waga</th>
        <th>Waga modelowa</th>
      </tr>
    </thead>
    <tbody>
      {props.input.map(subclass => (subclass.weight || subclass.modelWeight) ? <React.Fragment>
        <tr>
          <td>{subclass.name}</td>
          <td></td>
          <td></td>
          <td>{subclass.weight}</td>
          <td>{subclass.modelWeight}%</td>
        </tr>
        {subclass.entries.map(entry => <tr>
          <td></td>
          <td>{entry.name}</td>
          <td>{entry.value}</td>
          <td></td>
          <td></td>
        </tr>)}
      </React.Fragment> : null)}
    </tbody>
  </table>
}

const Recommendation = props => {
  return <table className="table is-fullwidth">
    <thead>
      <tr>
        <th>Podklasa</th>
        <th>Nazwa</th>
        <th>Waluta</th>
        <th>Rekomendacja</th>
        <th>Cena</th>
        <th>Ilość</th>
        <th>Wartość</th>
      </tr>
    </thead>
    <tbody>
      {props.input.map(subclass => subclass.entries.length > 0 && <React.Fragment>
        <tr>
          <td>{subclass.name}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {subclass.entries.map(entry => <tr>
          <td></td>
          <td>{entry.name}</td>
          <td>{entry.currency}</td>
          <td>{entry.recommendation}</td>
          <td>{entry.price}</td>
          <td>{entry.amount}</td>
          <td>{parseFloat(entry.price) * parseFloat(entry.amount)}</td>
        </tr>)}
      </React.Fragment>)}
    </tbody>
  </table>
}

const Difference = props => {
  const {pln, eur, usd, gbp, akcje, dluzne, gotowka, alternatywne} = props.input
  const options = {
    legend: {
      display: false
    }
  }
  const currencyDifference = {
    datasets: [{
        data: [
          pln, eur, gbp, usd
        ],
        backgroundColor: [
          '#00b894', '#00cec9', '#6c5ce7', '#fdcb6e'
        ]
    }],
    labels: [
        'PLN',
        'EUR',
        'GBP',
        'USD'
    ]
  }
  const structureData = {
    datasets: [{
        data: [
          akcje, dluzne, gotowka, alternatywne
        ],
        backgroundColor: [
          '#192a56', '#c23616', '#0097e6', '#44bd32'
        ]
    }],
    labels: [
        'Akcje',
        'Instrumenty dłużne',
        'Gotówka',
        'Alternatywne'
    ]
  }
  return <HorizontalBar data={structureData} options={options} />
  // return <div className='columns'>
  //   <div className='column'>
  //     <HorizontalBar data={currencyDifference} options={options} />
  //   </div>
  //   <div className='column'>
  //     <HorizontalBar data={structureData} options={options} />
  //   </div>
  // </div>
}

const Params = props => {
  const input = props.input
  console.log(JSON.stringify(input));
  const structureData = {
    datasets: [{
        data: [
          parseFloat(input.current.var1), parseFloat(input.current.var2), parseFloat(input.current.var3)
        ],
        backgroundColor: '#ff0000',
        label: 'aktualne'
    }, {
        data: [
          parseFloat(input.wanted.var1), parseFloat(input.wanted.var2), parseFloat(input.wanted.var3)
        ],
        backgroundColor: '#00ff00',
        label: 'pożądane'
    }],
    labels: [
        'max. obsunięcie',
        'max. strata',
        'stopa zwrotu'
    ]
  }
  return <div className='columns'>
    <div className='column'>
      <Bar data={structureData} />
    </div>
  </div>
}

export default observer(props => {
  const recommendation = getStore().records.find(el => el.id === props.match.params.id)
  if (!recommendation) return null
  const {current, wanted, recommendations, current_comment = '', model_comment = '', summary = ''} = recommendation.content
  console.log(JSON.stringify(recommendations));
  return <div className='container content' style={{maxWidth: '800px', padding: '20px 0'}}>
    <h1 className='title is-4'>Cel raportu</h1>
    Celem niniejszego raportu jest przegląd Pana istniejącego portfela inwestycyjnego, który posiada Pan w różnych instytucjach finansowych oraz przedstawienie rekomendacji inwestycyjnych o charakterze cyklicznym.
    <h1 className='title is-4'>Przegląd portfela inwestycyjnego z komentarzem</h1>
    <div dangerouslySetInnerHTML={{__html: md.render(current_comment)}} />
      <br />
    <StateCharts input={current} /><br />
    <CurrentConcrete input={subclassesSort(recommendation.content)} />
    Wzorcowa struktura portfela inwestycyjnego dla określonego dla Pana celu inwestycyjnego oraz tolerancji ryzyka przedstawia się w następujący sposób.
    <br />
    <br />
    <StateCharts input={wanted} /><br />
    <div dangerouslySetInnerHTML={{__html: md.render(model_comment)}} /><br />
    <div className='columns'>
      <div className='column'>
        <Difference input={calculateChanges(recommendation.content)} />
      </div>
      <div className='column'>
        <Params input={recommendation.content} />
      </div>
    </div>
    <h1 className='title is-4'>Rekomendacje inwestycyjne</h1>
    <Recommendation input={subclassesAggregate(recommendation.content)} />
    <h1 className='title is-4'>Podsumowanie</h1>
    <div dangerouslySetInnerHTML={{__html: md.render(summary)}} />
    <h1 className='title is-4'>Słownik pojęć</h1>
    <ul>
      <li>Szacowane maksymalne roczne obsunięcie kapitału – szacowany maksymalny spadek wartości portfela w losowo wybranym okresie rocznym</li>
      <li>Szacowana maksymalna strata w horyzoncie inwestycyjnym -szacowana maksymalna strata z inwestycji w sugerowanym horyzoncie inwestycyjnym</li>
      <li>Szacowana roczna stopa zwrotu – szacowana roczna stopa zwrotu z inwestycji przy założeniu podejścia statycznego oraz bazując jedynie na stopach zwrotu na poziomie klas aktywów będących w portfelu (nie biorąca pod uwagę specyfiki poszczególnych instrumentów finansowych)</li>
      <li>Relacja zysku do ryzyka – miara efektywności portfela wyliczana za pomocą wzoru: Szacowana roczna stopa zwrotu / wartość bezwzględna z Szacowanego maksymalnego rocznego obsunięcia kapitału lub zero (w zależności która wartość jest wyższa; im większa wartość tym portfel jest bardziej efektywny pod kątem zysku i ryzyka</li>
    </ul>
    <h1 className='title is-4'>Informacja dodatkowa</h1>
    <p>Rekomendację sporządził Radosław Piotrowski, Doradca Inwestycyjny</p>
    <p>Niniejszy materiał (zwany dalej Materiałem informacyjnym) został sporządzony przez Dom Maklerski AFS Sp. z o.o. z siedzibą w Warszawie (zwany dalej DM AFS)  ul. Pańska 73, Warszawa.</p>
    <p>DM AFS jest podmiotem posiadającym zezwolenie Komisji Nadzoru Finansowego na prowadzenie działalności maklerskiej, w tym na doradztwo inwestycyjne i przez nią nadzorowany. </p>
    <p>W opinii DM AFS niniejszy dokument został sporządzony z zachowaniem należytej staranności i rzetelności, z wykluczeniem jakiegokolwiek konfliktu interesów, który mógłby wpłynąć na jego treść.</p>
    <p>Materiał informacyjny  został przygotowany na podstawie danych źródłowych wskazanych w jego treści z zastrzeżeniem, że DM AFS nie jest uprawniony do oceny ich wiarygodności lub rzetelności. </p>
    <p>Ponadto, w  związku z przyjętymi zasadami sporządzania tego typu dokumentów,  informacje   zawarte w Materiale informacyjnym  podawane są w formie przetworzonej oraz uproszczonej przez DM AFS, dlatego też mogą mieć charakter niepełny oraz skondensowany w stosunku do materiałów źródłowych.</p>
    <p>Klient podejmując decyzję o zakupie lub sprzedaży określonych papierów wartościowych lub innych instrumentów finansowych powinien wziąć pod uwagę fakt występowania ryzyka, które wiąże się z podjęciem decyzji inwestycyjnej, a w szczególności możliwość zmiany ceny instrumentów finansowych będących przedmiotem tej decyzji wbrew założeniom przyjętym przez DM AFS i oczekiwaniom Klienta, a w konsekwencji braku  uzyskania założonych przez siebie zysków.</p>
    <p>W szczególności DM AFS zwraca uwagę, iż na cenę instrumentów finansowych ma wpływ wiele różnych czynników, które są lub mogą być niezależne od woli samego emitenta tych instrumentów i wyników jego działalności. Można do nich zaliczyć m. in. zmieniające się warunki ekonomiczne, prawne, polityczne i podatkowe, których niejednokrotnie nie można przewidzieć lub które mogą być trudne do przewidzenia.</p>
    <p>DM AFS informuje, iż inwestowanie środków w instrumenty finansowe wiąże się z ryzykiem utraty części lub całości zainwestowanych środków.</p>
    <p>O ile nie jest to wyraźnie określone w treści prospektu emisyjnego, warunków emisji lub innego dokumentu informacyjnego, instrumenty finansowe nie mają charakteru depozytu bankowego, nie są gwarantowane lub zabezpieczane przez DM AFS jak również nie są gwarantowane lub zabezpieczane przez Skarb Państwa lub inne instytucje państwowe; nie są także zobowiązaniem tych podmiotów  względem Klienta.</p>
    <p>Klient powinien dążyć do dywersyfikacji polegającej na odpowiednim zróżnicowaniu portfela instrumentów finansowych celem zmniejszenia globalnego poziomu ryzyka. </p>
    <p>Niniejszy materiał odzwierciedla opinie i wiedzę jego autorów na dzień sporządzenia materiału.</p>
    <p>Dodatkowych informacji udziela Zespół Doradztwa Inwestycyjnego DM AFS.</p>
  </div>
})
