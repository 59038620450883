import React from 'react'
import { getUrlFromInformation } from '../services/files'
import {observer} from 'mobx-react'

export default observer(props => {
  const filename = props.content[0].filename
  return <a className="panel-block is-active" onClick={() => {
      getUrlFromInformation(props, filename)}}>
    <span className="panel-icon">
      <i className="fal fa-file-pdf" aria-hidden="true"></i>
    </span>
    {filename}
    <div style={{color: 'grey', fontSize: '12px', paddingLeft: 10}}>
      {props.created_at}
    </div>
  </a>
})
