import React from 'react'
import {observer} from 'mobx-react'
import getStore from '../Store'
import File from '../components/File'

export default observer(props => {
  const agreements = getStore().records.filter(el => el.type === 'agreement')
  return <React.Fragment><nav className="panel">
    {agreements.map(el => <File {...el} />)}
  </nav></React.Fragment>
})
