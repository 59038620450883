import React from 'react'
import {observer} from 'mobx-react'
import getStore from '../Store'
import File from '../components/File'

export default observer(props => {
  const info = getStore().records.find(el => el.type === 'info')
  if (!info) return null
  const content = info.content
  console.log(content)
  return <div className='container' style={{paddingTop: 20}}>
    <h1 className='title is-1'>{content.name}{content.type === 'of' ? '(osoba fizyczna)' : '(osoba prawna)'}</h1>
    <h3 className='title is-3'>Dane adresowe</h3>
    <ul>
      <li><b>Adres zamieszkania</b> {content.address}</li>
      <li><b>Adres korespondencyjny</b> {content.postal}</li>
      <li><b>Adres US</b> {content.us}</li>
    </ul><br />
    <h3 className='title is-3'>Dane rejestrowe</h3>
    <ul>
      <li><b>NIP</b> {content.nip}</li>
      {content.type === 'of' ? <li><b>PESEL</b> {content.pesel}</li> : <li><b>Regon</b> {content.regon}</li>}
    </ul><br />
    <h3 className='title is-3'>Dane kontaktowe</h3>
    <ul>
      <li><b>Email</b> {content.email}</li>
      <li><b>Telefon</b> {content.phone}</li>
    </ul><br />
    <h3 className='title is-3'>Powiadomienia</h3>
    <ul>
      <li><b>Email</b> {content.notifications}</li>
    </ul>
  </div>
})
