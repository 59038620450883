import React, { Component } from 'react';
import { StorworkInit } from 'storwork-stores'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { Route, Link, withRouter } from 'react-router-dom'

import Agreements from './views/Agreements'
import Invoices from './views/Invoices'
import Profile from './views/Profile'
import Recommendations from './views/Recommendations'
import Recommendation from './views/Recommendation'

import Login from './Login'
import getStore from './Store'

const Application = withRouter(observer(props => {
  const files = props.store.records
  return <React.Fragment>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="http://rocksolidinvestments.pl/">
            <img src='/logo.png' />
          </a>

          <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link to='/invoices' className="navbar-item">
              Faktury
            </Link>
            <Link to='/agreements' className="navbar-item">
              Umowy
            </Link>
            <Link to='/recommendations' className="navbar-item">
              Rekomendacje
            </Link>
            <Link to='/profile' className="navbar-item">
              Profil
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-light">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Route exact path='/invoices' component={Invoices} />
      <Route exact path='/agreements' component={Agreements} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/recommendations' component={Recommendations} />
      <Route exact path='/recommendations/:id' component={Recommendation} />
  </React.Fragment>
}))

const ApplicationWithStore = props => <Application store={getStore()} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<ApplicationWithStore />} loggedOut={<Login />} />
  }
}

export default App;
