import React from 'react'
import {observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import getStore from '../Store'
import File from '../components/File'

export default observer(props => {
  const agreements = getStore().records.filter(el => el.type === 'recommendation')
  return <React.Fragment><nav className="panel">{agreements.map(el => <Link to={'/recommendations/' + el.id} className="panel-block is-active">
    <span className="panel-icon">
      <i className="fal fa-check" aria-hidden="true"></i>
    </span>
    Rekomendacja z {el.created_at}
  </Link>)}</nav></React.Fragment>
})
